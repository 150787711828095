import React from 'react'

import { withTranslation as translate } from 'react-i18next'

import Error from './Error'
import API from '../../../services/api'

class LocationSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      allRegions: [],
      activePromotions: [],
      selectedRegion: null,
      setBy: null
    }
  }

  componentDidMount () {
    const params = { operator_id: this.props.operatorUUID }

    API.fetchPromotions(params, promos => {
      this.setState({ activePromotions: promos })
      API.fetchLocations(params, locs => {
        this.setState({ allRegions: locs })
        // If a location is selected, ensure the selectedRegion is also set
        if (this.props.value && this.state.selectedRegion === null) {
          this.setState({
            selectedRegion: locs.find(r => {
              return r.locations.find(l => {
                return l.id === this.props.value
              })
            })?.id
          })
        }
      })
    })
  }

  // filter to include only open locations
  filterOpenLocations (data) {
    if (!data || data.length === 0) return []

    const regions = []
    data.forEach(r => {
      const open = r.locations.filter(l => l.isOpen)
      r.locations = open
      regions.push(r)
    })
    return regions
  }

  // filter to remove regions with no selected locations
  removeEmptyRegions (data) {
    if (!data || data.length === 0) return []

    return data.filter(r => r.locations.length > 0)
  }

  // filter to include only matched locations
  matchPromoLocations (data, locs) {
    if (!data || data.length === 0) return []

    const regions = []
    data.forEach(r => {
      const matched = r.locations.filter(l => locs.includes(l.id))
      r.locations = matched
      regions.push(r)
    })
    return regions
  }

  // if the promotion code is tied to certain locations we want to only have those locations
  //   available for selection, so create a filter of the appropriate location ids
  // otherwise we filter for open clubs only
  //  - ie user can't sign up to unopen club except with pre-sale promotions
  //  - can't sign up to a club not in the promotion restriction list
  filterRegions = (locs, promos) => {
    if (locs === null) return []

    const allLocations = JSON.parse(JSON.stringify(locs))
    let locFilter = null

    if (allLocations.length === 0) return allLocations

    // this is a bit of a hack to get cypress test passing
    if (process.env.REACT_APP_ENV === 'ci') {
      return allLocations
    }

    let locations = []
    if (promos.length > 0 && this.props && this.props.code) {
      const promotion = promos.find(ap => ap.code === this.props.code)

      if (promotion && promotion.locations && promotion.locations.length > 0) {
        locFilter = promotion.locations.map(elem => elem.id)
      }
      if (locFilter && locFilter.length > 0) {
        // filter the data to only have the appropriate locations
        locations = this.matchPromoLocations(allLocations, locFilter)
      } else {
        locations = this.filterOpenLocations(allLocations)
      }
    } else {
      locations = this.filterOpenLocations(allLocations)
    }

    const filtered = this.removeEmptyRegions(locations)
    // If we only have one region available set it as selected
    if (filtered.length === 1) {
      if (this.state.selectedRegion !== filtered[0].id) {
        this.setState({ selectedRegion: filtered[0].id })
      }
      // If we only have one location available set it as selected
      if (filtered[0].locations.length === 1) {
        if (this.props.value !== filtered[0].locations[0].id) {
          this.props.onChange({ target: { value: filtered[0].locations[0].id } })
        } else if (this.state.setBy === 'promoCode') {
          this.props.onChange({ target: { value: null } })
        }
      }
    }
    return filtered
  }

  onRegionChange = (event, regions) => {
    this.setState({ selectedRegion: event.target.value === '' ? null : event.target.value })
    const region = regions.find((r) => r.id === event.target.value)
    if (region && region.locations.length === 1) {
      if (this.props.value !== region.locations[0].id) {
        // If a region has only one location then set that as the users location
        this.props.onChange({ target: { value: region.locations[0].id } })
      }
    } else {
      // otherwise make sure any previously selected location is cleared
      this.props.onChange({ target: { value: undefined } }) // this clears the stored locationUUID
      event.target.nextSibling.value = null // this ensures the location-id display value is cleared
    }
  }

  onLocationChange = (event, regionMap) => {
    if (this.state.selectedRegion === null && event.target.value !== '') {
      this.setState({ selectedRegion: regionMap[event.target.value] })
    }
    this.props.onChange(event)
  }

  compare (a, b) {
    return a < b ? -1 : (a > b ? 1 : 0)
  }

  renderWithRegionSelector (data, t, errors) {
    const regions = data.sort((a, b) => this.compare(a.name, b.name)).map((region) => {
      const obj = {}
      obj[region.id] = region.locations
      return obj
    })
    const regionOptions = data.map((region) => {
      return <option key={region.id} value={region.id}>{region.name}</option>
    })

    // filter by selected region
    let filteredRegions = data
    if (this.state.selectedRegion !== null) {
      filteredRegions = data.filter((region) => region.id === this.state.selectedRegion)
    }

    const filteredLocations = filteredRegions.map((region) => {
      return region.locations
    }).flat().sort((a, b) => this.compare(a.name, b.name))

    const locationOptions = filteredLocations.map((location) => {
      return <option key={location.id} value={location.id}>{location.name}</option>
    })

    const locationRegionMap = {}
    filteredRegions.map((region) => (region.locations.map((loc) => (locationRegionMap[loc.id] = region.id))))

    return (
      <>
        <select
          className='regionSelect'
          ref={(input) => (this.props.inputRefs.locationId = input)}
          value={this.state.selectedRegion || ''}
          onChange={(e) => this.onRegionChange(e, data, regions)}
          id='region-select' name='regionName'
        >
          <option key='' value=''>{t('signup.club.region')}</option>
          {regionOptions}
        </select>
        <select
          className={`locationSelect ${errors.locationId ? 'error' : ''}`}
          value={this.props.value}
          onChange={(e) => this.onLocationChange(e, locationRegionMap)}
          id='location-select' name='locationId'
          disabled={this.state.selectedRegion === '' || this.state.selectedRegion === null}
        >
          <option key='' value=''>{t('signup.club.title')}</option>
          {locationOptions}
        </select>
        {errors.locationId && <Error messageKey='signup.profile.error.blank' />}
      </>
    )
  }

  renderWithoutRegionSelector (locations, t, errors) {
    const regions = locations.map((region) => {
      const locations = region.locations.map((location) =>
        <option key={location.id} value={location.id}>{location.name}</option>
      )

      return (
        <optgroup label={region.name} key={region.id}>
          {locations}
        </optgroup>
      )
    })

    return (
      <>
        <select
          className={`locationSelect ${errors.locationId ? 'error' : ''}`}
          ref={(input) => (this.props.inputRefs.locationId = input)}
          value={this.props.value} onChange={this.props.onChange}
          id='location-select'
        >
          <option>{t('signup.club.title')}</option>
          {regions}
        </select>
        {errors.locationId && <Error messageKey='signup.profile.error.blank' />}
      </>
    )
  }

  render () {
    const { t, errors } = this.props
    const locations = this.filterRegions(this.state.allRegions, this.state.activePromotions)

    if (this.props.showRegions) {
      return this.renderWithRegionSelector(locations, t, errors)
    } else {
      return this.renderWithoutRegionSelector(locations, t, errors)
    }
  }
}

export default translate()(LocationSelect)
