import { get, post } from './fetch'

const HTTP_API = {
  getTestOperatorUUID: function () {
    const request = new XMLHttpRequest()
    request.open('GET', 'https://api-gateway-signup-credlock.apps.redpill-linpro.com/v1/operators/test', false)
    request.send(null)

    if (request.status === 200) {
      return request.responseText
    }
  },

  updateQuote: (params, success) => {
    post(`/v1/operators/${params.operator_id}/quotes`, params, success)
  },

  completeQuote: (params, success, error) => {
    post(`/v1/operators/${params.operator_id}/quotes`, params, success, error)
  },

  fetchLocations: (params, success) => {
    get(`/v1/operators/${params.operator_id}/locations`, null, success)
  },

  fetchPromotions: (params, success) => {
    get(`/v1/operators/${params.operator_id}/promotions`, null, success)
  },

  getQuote: (params, success, error) => {
    get(`/v1/operators/${params.operator_id}/quotes/${params.quote_id}`, null, success, error)
  },

  makeSignupPayment: (params) => {
    post(`/v1/operators/${params.operator_id}/memberships`, params, null)
  },

  requestVerificationCode: (params, success) => {
    post(`/v1/operators/${params.operator_id}/quotes/${params.quote_id}/verify`, params, success)
  },

  confirmVerificationCode: (params, success, error) => {
    post(`/v1/operators/${params.operator_id}/quotes/${params.quote_id}/confirm/${params.code}`, params, success, error)
  },

  // NOTE: path is prefixed with "signup-ui-no.<env>.credlock.net", nginx rule redirects 'v1/operators'
  //   to signup-api, so for now including operators/op_id in path, even though not needed
  //   Alternative would be to add another path to the http config in digitalocean
  fetchPrefixList: (params, success, error) => {
    get(`/v1/operators/${params.operator_id}/prefix/list`, null, success)
  }
}

export default HTTP_API
